/* eslint-disable react/no-array-index-key */

import { clsx } from 'clsx';
import type { MouseEvent } from 'react';
import { forwardRef, useMemo } from 'react';
import type { TaggedUnion } from 'type-fest';
import { Flex } from '../../base/Flex/Flex';
import { Layout } from '../../base/Layout/Layout';
import { spacing } from '../../theme/spacing.css';
import { errorText } from '../../theme/utils.css';
import { Banner } from '../Banner/Banner';
import { Button } from '../Button/Button';
import type { ButtonProps } from '../Button/Button';
import { Checkbox } from '../Checkbox/Checkbox';
import { SortIcon } from './SortIcon';
import {
  cellStyles,
  emptyStateContainer,
  footerWrapper,
  headerWrapper,
  inlineTable,
  rowStyles,
  sortIconWrapper,
  tableAlert,
  tableAlertContainer,
  tableLoader,
  tableStyles,
  tableWrapper,
} from './table.css';
import { TableLoader } from './TableLoader';

// TODO: add table footer and pagination once design is ready

export enum ActionType {
  Dialog = 'dialog',
  Edit = 'edit',
  Navigate = 'navigate',
  Submit = 'submit',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Button = 'button',
  ButtonLink = 'button_link',
}

export type TableAction = TaggedUnion<
  'type',
  {
    readonly [ActionType.Navigate]: {
      readonly url: string;
    };
    readonly [ActionType.Button]: {
      readonly onClick: () => void;
      readonly disabled?: boolean;
    };
    // in order to support catching the link press and doing things like react-router navigate
    readonly [ActionType.ButtonLink]: {
      readonly onClick: () => void;
      readonly url: string;
    };
  }
>;

export interface TableItem {
  readonly value: JSX.Element | number | string | null;
  readonly warning?: boolean;
  readonly action?: TableAction;
  readonly hidden?: boolean;
}

export interface Checkable {
  readonly checkedIds: string[];
  readonly onRowChecked: (rowId: string) => void;
  readonly onAllChecked: (rowIds: string[]) => void;
  readonly disabledIds?: string[];
}

export type TableColumn = {
  readonly key: string;
  readonly name: string;
  readonly sortable?: boolean;
  readonly width?: number | string;
  readonly minWidth?: number | string;
  readonly hidden?: boolean;
};

export interface SortableSet {
  readonly sort: (key: string) => void;
  readonly sortingBy: string;
  readonly sortDirection: 'asc' | 'desc';
}

export type TableProps = {
  readonly alerts?: TableAlertProps[];
  readonly checkable?: Checkable;
  readonly className?: string;
  readonly data: Record<TableColumn[][number]['name'], TableItem>[];
  readonly columns: TableColumn[];
  readonly emptyComponent?: JSX.Element;
  readonly emptyMessage?: string;
  readonly footer?: JSX.Element;
  readonly header?: JSX.Element;
  /** When `true`, the table will be displayed inline, without borders. */
  readonly isInline?: boolean;
  readonly isLoading?: boolean;
  readonly rowIdentifier?: string;
  readonly sortableSet?: SortableSet;
};

function isSimpleValue(value: TableItem['value']): value is number | string {
  return typeof value === 'string' || typeof value === 'number';
}

function getRowId(
  row: TableProps['data'][number],
  rowIdentifier: Exclude<TableProps['rowIdentifier'], undefined>,
): string {
  if (typeof row[rowIdentifier].value !== 'string') {
    throw new Error(
      `rowIdentifier's value must be a string, rowIdentifier: "${rowIdentifier}", value: ${row[rowIdentifier].value?.toString()}`,
    );
  }

  return row[rowIdentifier].value as string;
}

function getAllRowIds(
  checked: boolean,
  rowIdentifier: Exclude<TableProps['rowIdentifier'], undefined>,
  data: TableProps['data'],
): string[] {
  const rowIds = data.map(row => getRowId(row, rowIdentifier));

  return checked ? rowIds : [];
}
const Value: React.FC<TableItem> = props => {
  const { value, warning, action } = props;

  if (value == null) {
    return <>-</>;
  } else if (action?.type === ActionType.ButtonLink) {
    // having both url and onClick in order to allow CMD+clicking and opening the link in a different tab
    return (
      <a
        href={action.url}
        onClick={(e: MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          action.onClick();
        }}
        rel="noreferrer"
      >
        {value}
      </a>
    );
  } else if (action?.type === ActionType.Button) {
    return (
      <Button
        disabled={action.disabled}
        label={isSimpleValue(value) ? value.toString() : ''}
        onClick={action.onClick}
        variant="secondary"
      />
    );
  }

  if (warning) {
    return <span className={errorText}>{value}</span>;
  }

  return value;
};

type TableHeadProps = {
  readonly cols: TableProps['columns'];
  readonly data: TableProps['data'];
  readonly sortableSet?: SortableSet;
  readonly checkable?: Checkable;
  readonly rowIdentifier: string;
};

const TableHead: React.FC<TableHeadProps> = ({ cols, sortableSet, checkable, rowIdentifier, data }) => {
  return (
    <thead>
      <tr>
        {checkable != null && (
          <Layout as="th" className={cellStyles({ type: 'isHeader', checkbox: true })}>
            <Checkbox
              checked={checkable.checkedIds.length === data.length}
              disabled={(checkable.disabledIds ?? []).length === data.length}
              onCheckedChange={(checked: boolean) => checkable.onAllChecked(getAllRowIds(checked, rowIdentifier, data))}
              variant="brand"
            />
          </Layout>
        )}
        {cols.map(({ name, sortable, key, hidden, ...restProps }) => {
          const sortedBy = Boolean(sortable) && sortableSet?.sortingBy === key;
          const isSortable = sortableSet && sortable;

          if (hidden === true) {
            return null;
          }

          return (
            <Layout
              as="th"
              className={cellStyles({ type: 'isHeader', isSortable })}
              key={key}
              {...restProps}
              aria-sort={sortedBy ? (sortableSet.sortDirection === 'asc' ? 'ascending' : 'descending') : undefined}
              onClick={isSortable ? () => sortableSet.sort(key) : undefined}
            >
              {name}
              {isSortable === true && (
                <Layout className={clsx(sortIconWrapper, sortedBy ? sortableSet.sortDirection : undefined)}>
                  <SortIcon />
                </Layout>
              )}
            </Layout>
          );
        })}
      </tr>
    </thead>
  );
};

export interface TableAlertProps {
  readonly rowId: string; // needed for alert positioning
  readonly title: JSX.Element | string;
  readonly variant: 'attention' | 'negative';
  readonly actions: {
    readonly label: string;
    readonly onClick: () => void;
    readonly variant: ButtonProps['variant'];
  }[];
}

const TableAlert: React.FC<TableAlertProps> = ({ rowId, title, variant, actions }) => (
  <Flex
    className={tableAlertContainer}
    // @ts-expect-error positionAnchor is not yet supported in csstype
    style={{ positionAnchor: `--table-alert-${rowId}` }}
  >
    <Flex alignItems="center" className={tableAlert({ variant })} gap={spacing.s06} justifyContent="flex-end">
      <Flex justifyContent="space-between" minWidth="50%">
        <Banner title={title} variant={variant} withBackground={false} withBorder={false} />
        <Flex alignItems="center" gap={spacing.s06}>
          {actions.map(({ label, onClick, variant: buttonVariant }) => (
            <Button key={label} label={label} onClick={onClick} size="sm" variant={buttonVariant} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  </Flex>
);

type TableBodyProps = {
  readonly cols: TableProps['columns'];
  readonly data: TableProps['data'];
  readonly checkable?: Checkable;
  readonly rowIdentifier: string;
  readonly hasAlerts: boolean;
};

const TableBody: React.FC<TableBodyProps> = ({ data, checkable, rowIdentifier, cols, hasAlerts }) => {
  const rows = useMemo(() => {
    return data.map((row, index) => (
      <tr
        className={rowStyles}
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        id={`row_${row.id?.value?.toString() ?? index}`}
        key={`row_${index}`}
        // @ts-expect-error anchorName is not yet supported in csstype
        style={hasAlerts ? { anchorName: `--table-alert-${getRowId(row, rowIdentifier)}` } : undefined}
      >
        {checkable != null && (
          <Layout as="td" className={cellStyles()}>
            <Checkbox
              checked={checkable.checkedIds.includes(getRowId(row, rowIdentifier))}
              disabled={(checkable.disabledIds ?? []).includes(getRowId(row, rowIdentifier))}
              onCheckedChange={() => checkable.onRowChecked(getRowId(row, rowIdentifier))}
              variant="brand"
            />
          </Layout>
        )}
        {cols.map(({ key }, idx) => {
          const cell = row[key];

          if (cell.hidden === true) {
            return null;
          }

          return (
            <Layout
              as="td"
              className={cellStyles({ type: index === data.length - 1 ? 'isLastRowCell' : undefined })}
              key={`row_${index}_cell_${idx}`}
            >
              <Value {...cell} />
            </Layout>
          );
        })}
      </tr>
    ));
  }, [hasAlerts, checkable, cols, data, rowIdentifier]);

  return <tbody>{rows}</tbody>;
};

export const Table = forwardRef<HTMLTableElement, TableProps>(
  (
    {
      data,
      columns,
      className: externalClassName,
      header,
      footer,
      isInline = false,
      sortableSet,
      checkable,
      rowIdentifier = 'id',
      isLoading,
      emptyMessage = 'No results',
      emptyComponent,
      alerts,
    },
    ref,
  ) => {
    const withHeaderComponent = Boolean(header);
    const withFooterComponent = Boolean(footer);

    const isEmpty = data.length === 0;
    const hasAlerts = alerts != null && alerts.length > 0;

    return (
      <div className={tableWrapper}>
        {withHeaderComponent && <div className={headerWrapper}>{header}</div>}

        {isLoading ? (
          <TableLoader className={tableLoader} />
        ) : isEmpty ? (
          emptyComponent ?? (
            <Flex className={emptyStateContainer} flexDirection="column" justifyContent="center" padding={spacing.s20}>
              <Banner title={emptyMessage} variant="info" />
            </Flex>
          )
        ) : (
          <>
            {hasAlerts && alerts.map(alert => <TableAlert key={alert.rowId} {...alert} />)}
            <Layout
              as="table"
              className={clsx(externalClassName, tableStyles, {
                [inlineTable]: isInline,
              })}
              ref={ref}
            >
              <TableHead
                checkable={checkable}
                cols={columns}
                data={data}
                rowIdentifier={rowIdentifier}
                sortableSet={sortableSet}
              />
              <TableBody
                checkable={checkable}
                cols={columns}
                data={data}
                hasAlerts={hasAlerts}
                rowIdentifier={rowIdentifier}
              />
            </Layout>
          </>
        )}
        {withFooterComponent && <div className={footerWrapper}>{footer}</div>}
      </div>
    );
  },
);

export * from './TableLoader';
